import { DataSource } from '@angular/cdk/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

export abstract class TableDataSource<T, R = T> extends DataSource<T> {
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private dataSubject = new Subject<T[]>();
    public data$ = this.dataSubject.asObservable();
    private _paginator: MatPaginator | null;
    private _sort: MatSort | null;
    protected subscriptions: Subscription[] = [];

    connect(): Observable<T[]> {
        return this.dataSubject.asObservable();
    }

    disconnect() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    get paginator(): MatPaginator | null {
        return this._paginator;
    }

    set paginator(paginator: MatPaginator | null) {
        this._paginator = paginator;
        if (paginator) {
            this.subscriptions.push(
                paginator.page.subscribe(() => this.reload()),
            );
        }
    }

    get sort(): MatSort | null {
        return this._sort;
    }

    set sort(sort: MatSort | null) {
        this._sort = sort;
        if (sort) {
            this.subscriptions.push(
                sort.sortChange.subscribe(() => this.reload()),
            );
        }
    }

    abstract load(query: RequestQueryBuilder): Observable<R>;

    protected createQuery(): RequestQueryBuilder {
        const builder = RequestQueryBuilder.create();
        if (this.paginator) {
            builder.setOffset(this.paginator.pageIndex * this.paginator.pageSize).setLimit(this.paginator.pageSize);
        }
        if (this.sort?.direction) {
            builder.sortBy({ field: this.sort.active, order: this.sort.direction === 'asc' ? 'ASC' : 'DESC' });
        }
        return builder;
    }

    mapResponse(res: R): T[] {
        return res as unknown as T[];
    }

    reload() {
        this.loadingSubject.next(true);
        this.load(this.createQuery())
            .pipe(
                map(res => this.mapResponse(res)),
                catchError(() => {
                    // TODO #4pqr07: inject alert service, but currently not possible without implementing the constructor, which means all extends need to pass it too.
                    return of(null);
                }),
                finalize(() => this.loadingSubject.next(false)),
            ).subscribe((response: T[]) => {
            this.dataSubject.next(response || []);
        });
    }
}
