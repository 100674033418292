import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AlertService {
    constructor(private snackBar: MatSnackBar) {
    }

    show(message: string, duration = 5000) {
        this.snackBar.open(message, '', { duration });
    }
}
