import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { CommonModule } from '../common.module';
import { AutocompleteListComponent } from './autocomplete-list.component';

@NgModule({
    declarations: [AutocompleteListComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatButtonModule,
        AutocompleteModule,
    ],
    exports: [AutocompleteListComponent],
})
export class AutocompleteListModule {
}
