import { Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'app-loading-container',
    templateUrl: './loading-container.component.html',
    styleUrls: ['./loading-container.component.scss'],
})
export class LoadingContainerComponent {
    @Input() loading: boolean;
    @Input() spinnerSize: number;
    @Input() spinnerColor: ThemePalette = 'primary';

    constructor() {
    }
}
