<div class="absolute-center">
    <mat-card class="box">
        <mat-card-title>
            <img src="assets/mm-logo-1500-light.png" alt="MIG Motors">
            <span class="name">CONNECT</span>
        </mat-card-title>
        <mat-card-content class="pb-3">
            <form #form="ngForm" class="text-center w-100">
                <mat-form-field class="w-100" appearance="outline" [hideRequiredMarker]="true">
                    <mat-label>E-mailadres</mat-label>
                    <input ngModel #username="ngModel" name="username" type="email" matInput required>
                </mat-form-field>
                <mat-form-field class="w-100" appearance="outline" [hideRequiredMarker]="true">
                    <mat-label>Wachtwoord</mat-label>
                    <input ngModel #password="ngModel" name="password" type="password" matInput required>
                </mat-form-field>
                <div class="warn-message mb-2 py-2" *ngIf="invalidCredentials">Foute gebruikersnaam of wachtwoord</div>
                <button mat-flat-button color="primary" class="w-100" (click)="login(username.value, password.value)" [ngClass]="{ 'invalid': form.invalid }">
                    Aanmelden
                </button>
            </form>
        </mat-card-content>
    </mat-card>
</div>
