import {
    AfterContentChecked,
    AfterContentInit,
    AfterViewInit,
    Component,
    ContentChildren,
    Input,
    OnChanges,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ColumnDirective } from './column.directive';
import { TableColumn } from './table-column.interface';
import { TableDataSource } from './table.data-source';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements AfterViewInit, AfterContentChecked, OnChanges {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ContentChildren(ColumnDirective) contentChildren: QueryList<ColumnDirective>;

    @Input() columns: TableColumn<T>[];
    @Input() pageSizeOptions: number[] = [10, 50, 100];
    @Input() dataSource: TableDataSource<T>;

    displayedColumns: string[];
    columnTemplates: { [key: string]: TemplateRef<ColumnDirective> } = {};
    defaultSortColumn?: TableColumn<T>;

    private viewInited = false;

    ngAfterContentChecked() {
        this.contentChildren.forEach(d => this.columnTemplates[d.key] = d.templateRef);
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.viewInited = true;
        this.dataSource.reload();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.columns?.currentValue) {
            const columns: TableColumn<T>[] = changes.columns.currentValue;
            this.displayedColumns = columns.map(col => '' + col.key);
            this.defaultSortColumn = columns.find(col => col.sortActive);
        }
        if (changes.dataSource?.currentValue && this.viewInited) {
            this.dataSource.reload();
        }
    }
}
