<div class="d-flex">
    <input name="search"
           type="text"
           [(ngModel)]="searchValue"
           #searchModel="ngModel"
           #searchField
           (ngModelChange)="searchInput.next($event);"
           [matAutocomplete]="groupFn ? groupedAuto : auto"
           [placeholder]="placeholder || ''"
           class="mat-input-element"> <i class="fas fa-times fa-lg" *ngIf="searchValue" (click)="reset()"></i>
    <mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true" [displayWith]="display" (optionSelected)="this.emitValue($event.option.value)">
        <mat-option *ngFor="let item of list" [value]="item">
            <ng-template [ngTemplateOutlet]="resultDirective?.templateRef || defaultDisplay" [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
            <ng-template #defaultDisplay>{{ display(item) }}</ng-template>
        </mat-option>
    </mat-autocomplete>
    <mat-autocomplete #groupedAuto="matAutocomplete" [autoActiveFirstOption]="true" [displayWith]="display" (optionSelected)="this.emitValue($event.option.value)">
        <mat-optgroup *ngFor="let group of (groupedList | keyvalue)" [label]="group.key">
            <mat-option *ngFor="let item of group.value" [value]="item">
                <ng-template [ngTemplateOutlet]="resultDirective?.templateRef || defaultDisplay" [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
                <ng-template #defaultDisplay>{{ display(item) }}</ng-template>
            </mat-option>
        </mat-optgroup>
    </mat-autocomplete>
</div>

<!-- ng-content is just here so we can use @ContentChild, but it has no direct effect on the view. -->
<ng-content></ng-content>
