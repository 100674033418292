import { NgModule } from '@angular/core';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '../common.module';
import { ColumnDirective } from './column.directive';
import { PaginatorI18n } from './paginator.i18n';
import { TableComponent } from './table.component';

@NgModule({
    declarations: [TableComponent, ColumnDirective],
    imports: [
        CommonModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,
    ],
    exports: [TableComponent, ColumnDirective],
    providers: [{ provide: MatPaginatorIntl, useClass: PaginatorI18n }],
})
export class TableModule {
}
