import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AUTH_CONFIG, AuthConfig } from './auth-config.interface';
import { TokenInfo } from './token/token-info.interface';

@Injectable()
export class AuthService {
    private jwtHelper = new JwtHelperService();

    constructor(@Inject(HttpClient) private http: HttpClient, @Inject(AUTH_CONFIG) private config: AuthConfig) {
    }

    get token(): string | null {
        return localStorage.getItem('token');
    }

    public login(username: string, password: string): Observable<boolean> {
        return this.http.post<{ accessToken: string }>(this.config.authApi + '/auth/login', { username, password })
            .pipe(
                map(response => {
                    if (response && response.accessToken) {
                        this.storeToken(response.accessToken);
                        return true;
                    }
                    return false;
                }),
                catchError(_ => {
                    return of(false);
                }),
            );
    }

    public getTokenInfo(): TokenInfo | null {
        return this.token ? this.decodeToken(this.token) : null;
    }

    private decodeToken(token: string): TokenInfo | null {
        return this.jwtHelper.decodeToken(token) || null;
    }

    private storeToken(token: string): void {
        localStorage.setItem('token', token);
    }

    public logout(): void {
        localStorage.removeItem('token');
    }

    public isAuthenticated(): boolean {
        return !!this.token && !this.jwtHelper.isTokenExpired(this.token);
    }

    public getPermissions(tokenInfo: TokenInfo): string[] {
        return (tokenInfo?.roles || []).reduce((permissions: string[], role) => permissions.concat(...(role.permissions || [])), []);
    }

    public hasPermissions(...requiredPermissions: string[]): boolean {
        const tokenInfo = this.getTokenInfo();
        const permissions = tokenInfo ? this.getPermissions(tokenInfo) : [];
        return requiredPermissions.every(requiredPermission => permissions.some(p => p === requiredPermission));
    }

    public verifyOTP(field: 'email' | 'mobile', value: string, code: string) {
        return this.http.post<{ accessToken: string }>(this.config.authApi + '/one-time-pass/activate', { field, value, code })
            .pipe(
                map(response => {
                    if (response && response.accessToken) {
                        this.storeToken(response.accessToken);
                        return true;
                    }
                    return false;
                }),
                catchError(_ => {
                    return of(false);
                }),
            );
    }
}
