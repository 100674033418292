import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BaseMatFormField } from '../forms';

@Component({
    selector: 'app-timepicker',
    templateUrl: './timepicker.component.html',
    styleUrls: ['./timepicker.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: TimepickerComponent }],
})
export class TimepickerComponent extends BaseMatFormField<number> {
    time: string | null;

    constructor(@Self() ngControl: NgControl, fm: FocusMonitor, elRef: ElementRef<HTMLElement>) {
        super(ngControl, fm, elRef);
    }

    get shouldLabelFloat(): boolean {
        return true;
    }

    setValue(value: number | null) {
        if (!value) {
            this.time = null;
            return;
        }
        value %= 24 * 60 * 60 * 1000;
        const hours = '' + Math.floor(value / (60 * 60 * 1000));
        value %= 60 * 60 * 1000;
        const minutes = '' + Math.floor(value / (60 * 1000));
        this.time = `${(hours.length == 2 ? hours : `0${hours}`)}:${(minutes.length == 2 ? minutes : `0${minutes}`)}`;
    }

    onTimeChange(time: string) {
        if (!time) {
            this.emitValue(null);
        } else {
            const matches = time.match(/(\d{2}):(\d{2})/);
            if (!matches) this.emitValue(null);
            else {
                const [, hours, minutes] = matches;
                this.emitValue((+hours * 60 * 60 * 1000) + (+minutes * 60 * 1000));
            }
        }
    }
}
