import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule, InfoBoxModule, LoadButtonModule } from '@lib/common';
import { AUTH_CONFIG, AuthConfig } from './auth-config.interface';
import { AuthGuard } from './auth.guard';
import { AuthInterceptor } from './auth.interceptor';
import { AuthService } from './auth.service';
import { LoginBaseComponent } from './login/login-base.component';

@NgModule({
    declarations: [LoginBaseComponent],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        MatInputModule,
        MatFormFieldModule,
        LoadButtonModule,
        InfoBoxModule,
    ],
    providers: [],
    exports: [LoginBaseComponent],
})
export class AuthModule {
    public static forRoot(config: AuthConfig): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                {
                    provide: AUTH_CONFIG,
                    useValue: config,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthInterceptor,
                    multi: true,
                },
                AuthGuard,
                AuthService,
            ],
        };
    }
}
