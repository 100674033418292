import { Component, ContentChild, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-login-base',
    templateUrl: './login-base.component.html',
    styleUrls: ['./login-base.component.scss'],
})
export class LoginBaseComponent implements OnInit {
    @ContentChild(NgForm) protected form: NgForm;
    @Output() loginResult = new EventEmitter<boolean>();

    invalidCredentials = false;
    loading = false;
    useDefaultForm: boolean;

    constructor(private authService: AuthService) {
    }

    ngOnInit() {
        this.useDefaultForm = !this.form;
    }

    async login(username: string, password: string) {
        this.invalidCredentials = false;
        this.loading = true;
        this.authService.login(username, password)
            .subscribe(loggedIn => {
                this.loading = false;
                this.invalidCredentials = !loggedIn;
                this.loginResult.emit(loggedIn);
            });
    }
}
