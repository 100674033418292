import { NgModule } from '@angular/core';
import { StatusBoxComponent } from './status-box.component';

@NgModule({
    declarations: [
        StatusBoxComponent,
    ],
    exports: [
        StatusBoxComponent,
    ],
})
export class StatusBoxModule {
}
