import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from 'libs/common/dialog/dialog.component';
import { BrowserSuggestion } from '../browser-suggestion.interface';

@Component({
    selector: 'app-browser-compability-dialog',
    templateUrl: './browser-compability-dialog.component.html',
    styleUrls: ['./browser-compability-dialog.component.scss'],
})
export class BrowserCompabilityDialogComponent extends DialogComponent {
    constructor(public dialogRef: MatDialogRef<BrowserCompabilityDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public suggestions: BrowserSuggestion[]) {
        super(dialogRef);
    }
}
