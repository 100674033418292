import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FilterTypes} from '../filter-types.enum';
import {Filter, FilterField} from '../filter-set';

@Component({
    selector: 'app-filter-input',
    templateUrl: './filter-input.component.html',
    styleUrls: ['./filter-input.component.scss']
})
export class FilterInputComponent {
    private filterTypeMap = {
        'text': FilterTypes.CONTAINS_INSENSITIVE,
        'select': FilterTypes.IN,
    };
    @Input() label: string;
    @Input() type: string;
    @Input() options: string[] | { display: string, value: string | boolean }[];
    @Input() field: string | FilterField;
    @Output() filter = new EventEmitter<Filter>();

    value: any;
    newValue: any;

    addFilter() {
        if (this.value != null) {
            let filterType = this.filterTypeMap[this.type];
            if (this.type === 'toggle') {
                if (this.value.value === false) {
                    filterType = FilterTypes.EQUALS;
                } else {
                    filterType = FilterTypes.NOT_EQUALS;
                }
                this.newValue = {display: this.value.display, value: null};
            } else {
                this.newValue = this.value;
            }
            this.filter.emit({
                field: this.field,
                value: this.newValue,
                filterType: filterType,
                displayName: this.label,
            });
        }
    }

}
