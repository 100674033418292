import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'money' })
export class MoneyPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) {
    }

    transform(value: any): string {
        return this.currencyPipe.transform(value, 'EUR');
    }
}
