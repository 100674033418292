<div class="d-flex flex-column h-100">
    <div class="list-overview flex-grow-1" *ngIf="!!groupFn; else noGrouping;">
        <div *ngFor="let groupedValues of (valuesByGroup! | keyvalue)" class="property-group">
            <h4>{{groupedValues.key}}</h4>
            <div class="property-group-items">
                <div *ngFor="let property of groupedValues.value; let i = index;" class="property-item my-1">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1 p-1 ellipsis">{{ property.value }}</div>
                        <button mat-button type="button" (click)="removeItemFromList(i, groupedValues.key)" color="warn"><i class="fas fa-trash-alt fa-md"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #noGrouping>
        <div *ngFor="let singleValue of value; let i = index;" class="property-item my-1">
            <div class="d-flex align-items-center">
                <div class="flex-grow-1 p-1 ellipsis">{{ singleValue.value }}</div>
                <button mat-button type="button" (click)="removeItemFromList(i)" color="warn"><i class="fas fa-trash-alt fa-md"></i></button>
            </div>
        </div>
    </ng-template>
    <div class="list-search">
        <mat-form-field>
            <mat-label *ngIf="!!label">{{label}}</mat-label>
            <app-autocomplete #search
                              ngModel
                              name="event-search"
                              [debounceTime]="400"
                              [minCharTrigger]="0"
                              [searchFn]="searchFn"
                              [displayFn]="displayFn"
                              [groupFn]="groupFn"
                              (ngModelChange)="onItemSelected($event); search.reset();"></app-autocomplete>
        </mat-form-field>
    </div>
</div>
