import { CommonModule as AngularCommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AlertService } from './alert.service';
import { CardModule } from './card';
import { DialogModule } from './dialog';

@NgModule({
    declarations: [],
    imports: [
        AngularCommonModule,
        MatSnackBarModule,
        FormsModule,
        CardModule,
        DialogModule,
    ],
    exports: [AngularCommonModule, CardModule, DialogModule],
})
export class CommonModule {
    public static forRoot(): ModuleWithProviders<CommonModule> {
        return {
            ngModule: CommonModule,
            providers: [AlertService],
        };
    }
}
