import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@lib/authentication';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    invalidCredentials = false;

    constructor(private router: Router, private authService: AuthService) {
    }

    ngOnInit() {
    }

    async login(username: string, password: string) {
        this.authService.login(username, password).subscribe(loggedIn => {
            if (loggedIn) {
                this.router.navigate(['/']);
            }
            this.invalidCredentials = !loggedIn;
        });
    }
}
