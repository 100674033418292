import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {
    constructor() {
    }

    transform(value: number): string | null {
        if (value == null) return null;
        if (value % (24 * 60 * 60 * 1000) === 0) {
            return value / (24 * 60 * 60 * 1000) + ' ' + $localize`:@@common-days:Days`;
        }
        if (value % (60 * 60 * 1000) === 0) {
            return value / (60 * 60 * 1000) + ' ' + $localize`:@@common-hours:Hours`;
        }
        return Math.floor(value / (60 * 1000)) + ' ' + $localize`:@@common-minutes:Minutes`;
    }
}
