import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ElementRef, HostBinding, Input, OnDestroy, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

export abstract class BaseMatFormField<T> implements MatFormFieldControl<T>, ControlValueAccessor, OnDestroy {
    static nextId = 0;

    @HostBinding() id = `app-custom-form-field-${BaseMatFormField.nextId++}`;
    @HostBinding('attr.aria-describedby') describedBy = '';

    @Input() readonly: boolean;
    @Input() name: string;

    private _placeholder: string;
    private _required = false;
    private _disabled = false;
    private _value: T | null;

    readonly stateChanges = new Subject<void>();
    controlType = 'app-autocomplete';

    focused: boolean;
    valueChangeDetectorFn: (_: any) => void;
    valueTouchedDetectorFn: (_: boolean) => void;

    protected constructor(
        @Self() public ngControl: NgControl,
        protected fm: FocusMonitor,
        protected elRef: ElementRef<HTMLElement>,
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
        fm.monitor(elRef.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        });
    }

    @Input()
    get placeholder() {
        return this._placeholder;
    }

    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get required() {
        return this._required;
    }

    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    get errorState(): boolean {
        return (this.ngControl && !!this.ngControl.touched) && !this.focused && (
            (!this.empty && !this.ngControl.valid) ||
            (this.empty && this.required)
        );
    }

    abstract setValue(value: T | null);

    emitValue(value: T | null) {
        this.value = value;
        this.stateChanges.next();
        if (this.valueChangeDetectorFn) this.valueChangeDetectorFn(value);
    }

    set value(value: T | null) {
        this._value = value;
        this.setValue(value);
    }

    get value(): T | null {
        return this._value;
    }

    get empty() {
        return !this.value;
    }

    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elRef.nativeElement);
    }

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(_: MouseEvent): void {
        this.valueTouchedDetectorFn(true);
    }

    writeValue(value: T): void {
        this.value = value;
    }

    registerOnChange(fn: () => void): void {
        this.valueChangeDetectorFn = fn;
    }

    registerOnTouched(fn: (_: boolean) => void): void {
        this.valueTouchedDetectorFn = fn;
    }
}
