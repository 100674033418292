import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '../common.module';
import { LoadingContainerComponent } from './loading-container.component';

@NgModule({
    declarations: [LoadingContainerComponent],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
    ],
    exports: [LoadingContainerComponent],
})
export class LoadingContainerModule {
}
