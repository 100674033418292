import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export class ApiHttpHandler implements HttpHandler {
    constructor(private baseUrl: string, private next: HttpHandler) {
    }

    handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        return this.next.handle(req.clone({ url: this.baseUrl + req.url }));
    }
}
