import { HttpHandler } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApiHttpClient } from './api.http-client';
import { ApiHttpHandler } from './api.http-handler';

@NgModule({
    providers: [],
})
export class ApiModule {
    public static forRoot(apiUrl: string): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [
                {
                    provide: ApiHttpHandler,
                    useFactory: (handler: HttpHandler) => new ApiHttpHandler(apiUrl, handler),
                    deps: [HttpHandler],
                },
                {
                    provide: ApiHttpClient,
                    useClass: ApiHttpClient,
                    deps: [ApiHttpHandler],
                },
            ],
        };
    }
}
