import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dash' })
export class DashPipe implements PipeTransform {
    constructor() {
    }

    transform(value: any): string {
        return value || '-';
    }
}
