import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as Bowser from 'bowser';
import { BrowserCompabilityDialogComponent } from './browser-compability-dialog/browser-compability-dialog.component';
import { BrowserSuggestion } from './browser-suggestion.interface';

@Injectable()
export class BrowserCompatibilityService {
    constructor(
        @Inject('BROWSER_BLACKLIST') private blacklist: { [browser: string]: string },
        @Inject('BROWSER_SUGGESTIONS') private suggestions: BrowserSuggestion[],
        private dialogService: MatDialog) {

    }

    public check() {
        const userBrowser = Bowser.getParser(window.navigator.userAgent);
        if (userBrowser.satisfies(this.blacklist)) {
            this.dialogService.open(BrowserCompabilityDialogComponent, { data: this.suggestions });
        }
    }
}
