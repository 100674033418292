import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class PaginatorI18n extends MatPaginatorIntl {
    constructor() {
        super();
        this.itemsPerPageLabel = $localize`:@@common-itemsPerPage:Items per page`;
        this.nextPageLabel = $localize`:@@common-nextPage:Next page`;
        this.previousPageLabel = $localize`:@@common-previousPage:Previous page`;
        this.getRangeLabel = function(page, pageSize, length) {
            if (length === 0 || pageSize === 0) {
                return '0 ' + $localize`:@@common-of:of` + ' ' + length;
            }
            length = Math.max(length, 0);
            const startIndex = page * pageSize;
            // If the start index exceeds the list length, do not try and fix the end index to the end.
            const endIndex = startIndex < length ?
                Math.min(startIndex + pageSize, length) :
                startIndex + pageSize;
            return startIndex + 1 + ' - ' + endIndex + ' ' + $localize`:@@common-of:of` + ' ' + length;
        };
    }
}
