<app-dialog heading="Check your browser" [hideActions]="true" class="box-medium">
    <div class="pb-4" i18n="@@common-browser-compability-text">Your browser is not supported, some features might not work as expected.<br>Please use one of the following browsers
        for optimal compatibility:
    </div>
    <div class="pb-4 text-center">
        <a *ngFor="let suggestion of suggestions" href="{{suggestion.url}}" target="blank" title="{{suggestion.name}}" [style.color]="suggestion.color"><i
            class="fab fa-{{suggestion.icon}} fa-3x p-2"></i></a></div>
    <div class="row-auto">
        <button type="submit" mat-flat-button color="primary" (click)="dialogRef.close(false)" i18n="@@common-ok">OK</button>
    </div>
</app-dialog>
