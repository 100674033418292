import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ContentChild, ElementRef, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { BaseMatFormField } from '../forms';
import { AutocompleteResultDirective } from './autocomplete-result.directive';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: AutocompleteComponent }],
})
export class AutocompleteComponent<T> extends BaseMatFormField<T> implements OnInit {
    @ViewChild('searchModel') searchModel: NgModel;
    @ViewChild('searchField') searchField: ElementRef;
    @ContentChild(AutocompleteResultDirective) resultDirective?: AutocompleteResultDirective;

    /**
     * This component serves as a MatFormField with ControlValueAccessor (can be used with ngModel)
     * The component is comprised of an input with a MatAutoComplete dropdown
     * The object presented in the dropdown are acquired by calling @param searchFn with argument @param searchValue
     *
     * @param value         The selected object or null. Accessed through NgModel, this is the outputted value of this MatFormField.
     *                      value is set from the template upon selection in the dropdown or from outside of the component through
     *                      binding (ngModel). When a new search is triggered, value is reset to null
     * @param searchValue   The search string. Two-way bound to this component's input field. Upon selection the MatAutoComplete will
     *                      set the value of the input field (and thus the bound @param searchValue) to be the selected object.
     *                      This object value will be ignored for searches
     */

    @Input() displayFn: (obj: T) => string;
    @Input() searchFn: (search: string) => Observable<T[]>;
    @Input() groupFn?: (list: T[]) => { [key: string]: T[] };
    @Input() minCharTrigger = 0;
    @Input() debounceTime = 0;

    searchValue: string | T | null;
    list: T[] = [];
    groupedList: { [key: string]: T[] } = {};
    searchInput = new BehaviorSubject<string | T | null>('');
    display = (val: T | null) => val && this.displayFn(val);

    constructor(
        @Optional() @Self() ngControl: NgControl,
        fm: FocusMonitor,
        elRef: ElementRef<HTMLElement>,
    ) {
        super(ngControl, fm, elRef);
    }

    // override
    get empty() {
        return !this.searchValue;
    }

    ngOnInit(): void {
        if (!this.displayFn) throw new Error('No display fn implemented');
        if (!this.searchFn) throw new Error('No search fn implemented');
        this.searchInput.pipe(
            filter(value => typeof value === 'string' || value == null),  // object value set by autocomplete -> not a valid search -> ignore
            tap(() => {
                if (this.value) this.value = null;   // string value set by input -> new search triggered -> reset outputted value to null
            }),
            debounceTime(this.debounceTime),
        ).subscribe((search: string) => {
            search = search || '';
            if (search.length >= this.minCharTrigger) {
                this.searchFn(search).subscribe(result => this.setList(result));
            } else {
                this.setList([]);
            }
        });
    }

    setValue(value: T | null) {
        if (value != null) {
            this.searchValue = value;
            this.searchInput.next(this.searchValue);
        } else if (typeof this.searchValue === 'object') {    // reset through binding
            this.searchModel.reset();
        }
    }

    reset() {
        this.searchModel.reset();
        this.searchField.nativeElement.blur();
    }

    setList(list: T[]) {
        this.list = list;
        if (this.groupFn) this.groupedList = this.groupFn(this.list);
    }
}
