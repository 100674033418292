<div class="table-container">
    <table class="w-100" mat-table matSort [dataSource]="dataSource" [matSortActive]="defaultSortColumn?.key" [matSortDirection]="defaultSortColumn?.sortActive">
        <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.key">
            <th class="text-center full-border" mat-header-cell mat-sort-header [disabled]="!column.sort" *matHeaderCellDef>{{ column.label }}</th>
            <td mat-cell *matCellDef="let row; let i = index">
                <ng-template [ngTemplateOutlet]="columnTemplates[column.key] || defaultDisplay"
                             [ngTemplateOutletContext]="{ $implicit: row, index: paginator.pageIndex * paginator.pageSize + i }"></ng-template>
                <ng-template #defaultDisplay>
                    <div>{{ row[column.key] }}</div>
                </ng-template>
            </td>
        </ng-container>
        <tr class="heading" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
</div>
<ng-content></ng-content>
