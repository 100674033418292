import { NgModule } from '@angular/core';
import { CommonModule } from '../common.module';
import { InfoBoxComponent } from './info-box.component';

@NgModule({
    declarations: [
        InfoBoxComponent,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        InfoBoxComponent,
    ],
})
export class InfoBoxModule {
}
