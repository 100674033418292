import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadButtonComponent } from './load-button.component';

@NgModule({
    declarations: [
        LoadButtonComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatProgressSpinnerModule,
    ],
    exports: [
        LoadButtonComponent,
    ],
})
export class LoadButtonModule {
}
