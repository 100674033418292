import { Component, HostBinding, Input, OnChanges, OnInit } from '@angular/core';

@Component({
    selector: 'app-square-wrapper',
    templateUrl: './square-wrapper.component.html',
    styleUrls: ['./square-wrapper.component.scss']
})
export class SquareWrapperComponent implements OnInit, OnChanges {
    @Input() ratio: number;
    @HostBinding('style.padding-bottom') padding = '100%';

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        if (this.ratio) {
            this.padding = this.ratio * 100 + '%';
        }
    }
}
