import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
    constructor(@Inject(AuthService) private authService: AuthService, @Inject(Router) private router: Router) {
    }

    canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> |
        Promise<boolean | UrlTree> | boolean | UrlTree {
        if (state.url === '/login' && this.authService.isAuthenticated()) {
            return this.router.createUrlTree(['/']);
        } else if (state.url === '/logout') {
            this.authService.logout();
            return this.router.createUrlTree(['/login']);
        } else if (state.url !== '/login' && state.url !== '/logout' && !this.authService.isAuthenticated()) {
            this.authService.logout();
            return this.router.createUrlTree(['/login']);
        }
        return true;
    }

    canActivateChild(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): boolean | UrlTree {
        if (!this.authService.isAuthenticated()) {
            return this.router.createUrlTree(['/login']);
        }
        return true;
    }

    /* tslint:disable:variable-name */
    canLoad(_route: Route, _urlSegments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isAuthenticated()) {
            return this.router.navigate(['/login']);
        }
        return true;
    }
}
