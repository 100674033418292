export enum FilterTypes {
    EQUALS = '$eq',
    NOT_EQUALS = '$ne',
    LESS_THAN = '$lt',
    GREATER_THAN = '$gt',
    LESS_THAN_EQUALS = '$lte',
    GREATER_THAN_EQUALS = '$gte',
    IN = '$in',
    NOT_IN = '$notin',
    CONTAINS = '$cont',
    CONTAINS_INSENSITIVE = '$contL',
    NOT_CONTAINS = '$excl',
}
