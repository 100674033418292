import { NgModule } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FormsModule } from '@angular/forms';
import { DashPipe } from '../util/dash.pipe';
import { CompatDatepickerToggleComponent } from '../util/compat-datepicker-toggle/compat-datepicker-toggle.component';
import { MoneyPipe } from '../util/money.pipe';
import { FilterInputComponent } from '../util/filter-input/filter-input.component';
import { SquareWrapperComponent } from '../util/square-wrapper/square-wrapper.component';
import { CommonModule } from '@lib/common';

@NgModule({
    declarations: [
        CompatDatepickerToggleComponent,
        FilterInputComponent,
        SquareWrapperComponent,
        DashPipe,
        MoneyPipe,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
    ],
    exports: [
        CommonModule,
        CompatDatepickerToggleComponent,
        MaterialModule,
        FormsModule,
        DashPipe,
        FilterInputComponent,
        SquareWrapperComponent,
        MoneyPipe,
    ],
    providers: [
        DatePipe,
        CurrencyPipe,
    ]
})
export class SharedModule {
}
