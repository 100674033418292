import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogModule } from '../dialog';
import { BrowserCompabilityDialogComponent } from './browser-compability-dialog/browser-compability-dialog.component';
import { BrowserCompatibilityService } from './browser-compatibility.service';
import { BrowserSuggestion } from './browser-suggestion.interface';

const defaultBlacklist: { [browser: string]: string } = {
    'Internet Explorer': '<12',
    'Microsoft Edge': '<75',
};
const defaultSuggestions: BrowserSuggestion[] = [
    { name: 'Chrome', url: 'https://www.google.com/chrome/', icon: 'chrome', color: '#008bde' },
    { name: 'Firefox', url: 'https://www.mozilla.org/nl/firefox/new/', icon: 'firefox', color: 'orange' },
    { name: 'Safari', url: 'https://support.apple.com/downloads/safari', icon: 'safari', color: '#00c3ff' },
    { name: 'Opera', url: 'https://www.opera.com/nl/computer', icon: 'opera', color: 'red' },
    // {name: 'Internet Explorer', url: 'https://www.microsoft.com/nl-be/download/details.aspx?id=41628', icon: 'internet-explorer'},
    // {name: 'Microsoft Edge', url: 'https://www.microsoft.com/en-us/edge', icon: 'edge'}
];

@NgModule({
    declarations: [BrowserCompabilityDialogComponent],
    imports: [
        CommonModule,
        DialogModule,
        MatDialogModule,
        MatButtonModule,
    ],
    providers: [BrowserCompatibilityService],
})
export class BrowserCompatibilityModule {
    static forRoot(
        blacklist = defaultBlacklist,
        suggestions = defaultSuggestions,
    ): ModuleWithProviders<BrowserCompatibilityModule> {
        return {
            ngModule: BrowserCompatibilityModule,
            providers: [
                BrowserCompatibilityService,
                { provide: 'BROWSER_BLACKLIST', useValue: blacklist },
                { provide: 'BROWSER_SUGGESTIONS', useValue: suggestions },
            ],
        };
    }
}
