import { Component, ContentChild, HostBinding, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
    @Input() heading: string;
    @Input() hideActions = false;
    @Input() loading = false;
    @ContentChild(NgForm) form: NgForm;
    @HostBinding('class.box-base') class = true;

    constructor(public dialogRef: MatDialogRef<any>) {
    }
}
