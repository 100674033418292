<form #form="ngForm" (ngSubmit)="addFilter(); form.reset();" class="w-100">
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'select'">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>{{label}}</mat-label>
                <mat-select [(ngModel)]="value" [name]="'filter-select-' + label" (selectionChange)="form.ngSubmit.emit()">
                    <mat-option *ngFor="let option of options"
                                [value]="option">{{option.display || option}}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'toggle'">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>{{label}}</mat-label>
                <mat-select [(ngModel)]="value" [name]="'filter-check-' + label" (selectionChange)="form.ngSubmit.emit()">
                    <mat-option *ngFor="let option of options"
                                [value]="option">{{option.display || option}}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
            <div class="w-100 d-flex">
                <mat-form-field class="flex-grow-1" appearance="outline">
                    <mat-label>{{label}}</mat-label>
                    <input [(ngModel)]="value" type="text" [name]="'filter-text-' + label" matInput>
                </mat-form-field>
                <button type="submit" class="flex-grow-0 ml-1 my-0" mat-flat-button color="primary">+</button>
            </div>
        </ng-container>
    </ng-container>
</form>
