import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CardModule } from '../card/card.module';
import { LoadButtonModule } from '../load-button/load-button.module';
import { DialogComponent } from './dialog.component';

@NgModule({
    declarations: [
        DialogComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        LoadButtonModule,
        CardModule,
    ],
    exports: [
        DialogComponent,
    ],
})
export class DialogModule {
}
