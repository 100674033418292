import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'app-load-button',
    templateUrl: './load-button.component.html',
    styleUrls: ['./load-button.component.scss'],
})
export class LoadButtonComponent {
    @Input() buttonType: string;
    @Input() class: string;
    @Input() color: ThemePalette;
    @Input() spinnerColor: ThemePalette;
    @Input() disabled: boolean;
    @Input() loading: boolean;
    @Output() loadButtonClick = new EventEmitter();
}
