<ng-content></ng-content>
<ng-container *ngIf="useDefaultForm">
    <form #form="ngForm">
        <mat-form-field [hideRequiredMarker]="true" class="w-100">
            <mat-label i18n="@@auth-email-address">Email address</mat-label>
            <input #username="ngModel" matInput name="username" ngModel required type="email">
        </mat-form-field>
        <mat-form-field [hideRequiredMarker]="true" class="w-100">
            <mat-label i18n="@@auth-password">Password</mat-label>
            <input #password="ngModel" matInput name="password" ngModel required type="password">
        </mat-form-field>
        <app-info-box *ngIf="invalidCredentials" class="d-block mb-3" type="warn" i18n="@@auth-login-invalid">Invalid username or password.</app-info-box>
        <app-load-button [loading]="loading" (click)="login(username.value, password.value)" [disabled]="form.invalid" class="w-100" color="accent" i18n="@@auth-login">Login
        </app-load-button>
    </form>
</ng-container>
