import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { flatten } from 'lodash';

@Component({
    selector: 'app-autocomplete-list',
    templateUrl: './autocomplete-list.component.html',
    styleUrls: ['./autocomplete-list.component.scss'],
})
export class AutocompleteListComponent<T> implements OnChanges {
    @Input() value: T[] = [];
    @Output() valueChange = new EventEmitter();

    @Input() label?: string;
    @Input() displayFn: (obj: T) => string;
    @Input() searchFn: (search: string) => Observable<T[]>;
    @Input() groupFn?: (list: T[]) => { [key: string]: T[] };
    @Input() minCharTrigger = 0;
    @Input() debounceTime = 0;

    valuesByGroup?: { [group: string]: T[] } = {};


    ngOnChanges(changes: SimpleChanges): void {
        this.value = this.value || [];
        this.triggerChanges();
    }

    onItemSelected(property: T | null) {
        if (property) {
            this.value.push(property);
            this.triggerChanges();
        }
    }

    private triggerChanges() {
        if (this.groupFn) this.valuesByGroup = this.groupFn(this.value);
        this.valueChange.emit(this.value);
    }

    removeItemFromList(index: number, group?: string) {
        if (group != undefined) {
            this.valuesByGroup![group].splice(index, 1);
            this.value = flatten(Object.values(this.valuesByGroup!));
        } else {
            this.value.splice(index, 1);
        }
        this.triggerChanges();
    }
}
