import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl-BE';
import { AuthInterceptor, AuthModule } from '@lib/authentication';
import { environment } from '../environments/environment';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MaterialModule } from './material/material.module';
import { ApiModule, CommonModule } from '@lib/common';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        AuthModule.forRoot({ authApi: environment.authApi }),

        CommonModule.forRoot(),
        MaterialModule.forRoot(),
        ApiModule.forRoot(environment.api),

    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'nl'
        }, /*{
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true
        }, */{
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }, {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        registerLocaleData(localeNl, 'nl');
    }
}
