import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommonModule } from '../common.module';
import { AutocompleteResultDirective } from './autocomplete-result.directive';
import { AutocompleteComponent } from './autocomplete.component';

@NgModule({
    declarations: [
        AutocompleteComponent,
        AutocompleteResultDirective,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
    ],
    exports: [
        AutocompleteComponent,
        AutocompleteResultDirective,
    ],
})
export class AutocompleteModule {
}
