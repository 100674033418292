import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-info-box',
    templateUrl: './info-box.component.html',
    styleUrls: ['./info-box.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class InfoBoxComponent implements OnInit {
    @Input() type: 'info' | 'warn' = 'info';

    constructor() {
    }

    ngOnInit(): void {
    }
}
