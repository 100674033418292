import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatFormFieldControl } from '@angular/material/form-field';
import * as moment from 'moment';
import { Moment } from 'moment';
import { BaseMatFormField } from '../forms';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: DatepickerComponent }],
})
export class DatepickerComponent extends BaseMatFormField<Date> {
    pickerMoment: Moment | null;

    constructor(@Self() ngControl: NgControl, fm: FocusMonitor, elRef: ElementRef<HTMLElement>) {
        super(ngControl, fm, elRef);
    }

    setValue(value: Date | null) {
        this.pickerMoment = value ? moment(value) : null;
    }

    onDateChange(inputEvent: MatDatepickerInputEvent<unknown>) {
        const val = inputEvent.value as Moment | null;
        this.emitValue(val?.toDate() || null);
    }

    // // override
    // onContainerClick(event: MouseEvent) {
    //     super.onContainerClick(event);
    //     this.elRef.nativeElement.querySelector('input')!.focus();
    // }
}
