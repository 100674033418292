import { Component, Input, OnInit } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
    selector: 'app-compat-datepicker-toggle',
    templateUrl: './compat-datepicker-toggle.component.html',
    styleUrls: ['./compat-datepicker-toggle.component.scss']
})
export class CompatDatepickerToggleComponent<D> implements OnInit {
    // renamed 'for' as to be analogous to the native DatepickerToggle
    // tslint:disable-next-line:no-input-rename
    @Input('for') datePicker: MatDatepicker<D>;

    constructor() {
    }

    ngOnInit() {
    }

}
