import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '../common.module';
import { TimepickerComponent } from './timepicker.component';

@NgModule({
    declarations: [TimepickerComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    providers: [],
    exports: [TimepickerComponent],
})
export class TimepickerModule {
}
