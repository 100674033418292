import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[appColumn]',
})
export class ColumnDirective {
    @Input('appColumn') key: string;

    constructor(public templateRef: TemplateRef<ColumnDirective>) {
    }
}
